// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutprogramme-js": () => import("./../../../src/pages/aboutprogramme.js" /* webpackChunkName: "component---src-pages-aboutprogramme-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-biopage-js": () => import("./../../../src/pages/biopage.js" /* webpackChunkName: "component---src-pages-biopage-js" */),
  "component---src-pages-clinicaltrials-js": () => import("./../../../src/pages/clinicaltrials.js" /* webpackChunkName: "component---src-pages-clinicaltrials-js" */),
  "component---src-pages-feasibility-study-js": () => import("./../../../src/pages/feasibility-study.js" /* webpackChunkName: "component---src-pages-feasibility-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-randomised-controlled-trial-js": () => import("./../../../src/pages/randomised-controlled-trial.js" /* webpackChunkName: "component---src-pages-randomised-controlled-trial-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

